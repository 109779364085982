<template>
  <div>
    <b-button class="px-0 forumCommonTopicsBtn" variant="link" @click="open = true">What are others talking about?</b-button>
    <t-modal v-model="open" @show="!wordCloudData && init()" size="xxl">
      <template #modal-title>
      <div class="d-flex">
        <span>Common Phrases in Topics</span>
      </div>
      </template>
      <fluency-loader v-if="loading"></fluency-loader>
      <div v-else class="d-flex" style="min-height: 600px;">
        <div class="col-7 p-5">
          <word-cloud :data-prop="wordCloudData" :dimensions="{height: 600}"></word-cloud>
        </div>
        <div class="col-5 pl-5 pr-0">
          <t-card>
            <fluent-table :items="tableData"
                          :fields="fields"
                          primaryKey="phrase"
                          :use-flex-classes="false">
              <template v-slot:cell(actions)="row">
                <div class="d-flex">
                  <div class="table-action-container">
                    <t-button-inline v-p-tooltip.top="'View Topics'"  @click="viewTopics(row.item)"
                                     size="small" severity="secondary" class="mx-1">
                      <fluency-icon type="show" />
                    </t-button-inline>
                    <t-button-inline v-p-tooltip.top="'Tag Topics'" @click="tagTopics(row.item)"
                                     size="small" severity="secondary" class="mx-1">
                      <fluency-icon type="tag" />
                    </t-button-inline>
                  </div>
                </div>
              </template>
            </fluent-table>
          </t-card>
        </div>
      </div>
    </t-modal>
  </div>
</template>

<script>
import FluencyLoader from 'core-ui/components/common/fluencyLoader'
import WordCloud from 'core-ui/components/reporting/wordCloud'
const commonWords = ['several', 'cannot', 'instead', 'anything', 'believe', 'thought', 'working', 'looking', 'something', 'because', 'much', 'very', 'regard', 'regards', 'through', 'each', 'many', 'below', 'kindly', 'kind', 'morning', 'good', 'though', 'even', 'rather', 'say', 'then', 'take', 'about', 'noticed', 'reason', 'back', 'says', 'wondering', 'getting', 'did', 'here', 'had', 'now', 'tried', 'dont', 'ive', 'seeing', 'team', 'look', 'looks', 'thank', 'thanks', 'noticed', 'reason', 'able', 'their', 'them', 'still', 'your', 'help', 'get', 'after', 'link', 'other', 'needed', 'however', 'possible', 'set', 'showing', 'could', 'our', 'see', 'know', 'found', 'appears', 'being', 'out', 'will', 'know', 'just', 'sure', 'they', 'only', 'which', 'should', 'like', 'does', 'need', 'show', 'today', 'cant', 'off', 'across', 'within', 'better', '0', 'unable', 'these', 'let', 'from', 'also', 'by', 'hey', 'up', 'or', 'how', '', 'do', 'hi', 'why', 'any', 'am', 'my', 'im', 'thank', 'was', 'me', 'as', 'an', 'there', 'of', 'be', 'have', 'with', 'are', 'on', 'but', 'can', 'you', 'in', 'not', 'it', 'that', 'and', 'a', 'this', 'is', 'for', 'i', 'to', 'the', 'you', 'issues', 'trying', 'hello', 'issue', 'at', 'look', 'some', 'were', 'way', 'assist', 'so', 'what', 'has', 'isnt', 'do', 'add', 'having', 'into', 'please', 'been', 'all', 'would', 'when', 'if', 'we', 'no', 'its', 'why']
const singleWordAllowList = ['blueprint', 'budget', 'manage', 'notification', 'dashboard', 'broadcast', 'launch']
export default {
  name: 'ForumCommonTopics',
  components: { WordCloud, FluencyLoader },
  data () {
    return {
      topics: undefined,
      loading: false,
      open: false,
      wordCloudData: undefined,
      tableData: undefined,
      fields: [
        {
          key: 'phrase',
          defaultWidth: 'auto'
        },
        {
          key: 'matches',
          label: 'Topics',
          defaultWidth: '80'
        },
        {
          key: 'actions',
          label: '',
          sortable: false,
          defaultWidth: '100'
        }
      ]
    }
  },
  methods: {
    async init () {
      this.loading = true
      const resp = await this.$res.forum.listAll()
      if (resp) {
        this.topics = resp
        this.analyze()
      }
      this.loading = false
    },
    unPlural (text) {
      if (text.endsWith('s')) {
        return text.substr(0, text.length - 1)
      } else {
        return text
      }
    },
    analyze () {
      const counts = {}
      for (const topic of this.topics) {
        const viableText = this.$filters.removeMarkdown(`${topic.title} ${topic.message}`)
          .toLowerCase()
          .replace(/[^a-z0-9\s]/g, '')
          .replace(/ad group/g, 'adgroup')
          .split(/\s/)
          .filter(w => !commonWords.includes(w) && w.length > 1)

        for (let i = 0; i < viableText.length; i++) {
          const word = this.unPlural(viableText[i])
          const wordPlusOne = viableText[i + 1] ? `${word} ${this.unPlural(viableText[i + 1])}` : ''
          // for three word phrases. I didn't find this very useful
          // const wordPlusTwo = viableText[i + 1] && viableText[i + 2] ? `${word} ${this.unPlural(viableText[i + 1])} ${this.unPlural(viableText[i + 2])}` : ''
          // if (wordPlusTwo && counts[wordPlusTwo] && !counts[wordPlusTwo].includes(topic)) {
          //   counts[wordPlusTwo].push(topic)
          //   i += 2
          //   continue
          // } else if (wordPlusTwo && !counts[wordPlusTwo]) {
          //   counts[wordPlusTwo] = [topic]
          // }
          if (wordPlusOne && counts[wordPlusOne] && !counts[wordPlusOne].includes(topic)) {
            counts[wordPlusOne].push(topic)
            i++
            continue
          } else if (wordPlusOne && !counts[wordPlusOne]) {
            counts[wordPlusOne] = [topic]
          }
          if (singleWordAllowList.includes(word)) {
            if (counts[word] && !counts[word].includes(topic)) {
              counts[word].push(topic)
            } else if (!counts[word]) {
              counts[word] = [topic]
            }
          }
        }
      }
      let wordCounts = []
      for (const entry of Object.entries(counts)) {
        const modifiedEntry = [entry[0].replace('adgroup', 'ad group'), entry[1]]
        wordCounts.push(modifiedEntry)
      }
      wordCounts = wordCounts.sort((a, b) => a[1].length > b[1].length ? -1 : 1).slice(0, 40).filter(wc => wc[1].length > 1)
      this.tableData = wordCounts.map(w => ({ phrase: w[0], matches: w[1].length, topics: w[1] }))
      const ceiling = wordCounts.reduce((accumulator, current) => {
        if (current[1].length > accumulator || accumulator === -1) {
          return current[1].length
        } else {
          return accumulator
        }
      }, -1)
      const floor = wordCounts.reduce((accumulator, current) => {
        if (current[1].length < accumulator || accumulator === -1) {
          return current[1].length
        } else {
          return accumulator
        }
      }, -1)
      this.wordCloudData = wordCounts.map(d => ([d[0], (d[1].length - floor) / (ceiling - floor) * 19.01 + 1]))
    },
    viewTopics ({ topics, phrase }) {
      this.$store.commit('forum/searchResults', topics)
      this.$store.commit('forum/customGroup', phrase)
      this.open = false
    },
    async tagTopics ({ topics, phrase }) {
      const tag = await this.$prompt({ title: 'Tag Topics', text: 'Tag all of the matching topics with:', defaultValue: phrase })
      if (tag) {
        this.loading = true
        const taggedTopics = topics.map(topic => {
          const modTopic = { ...topic }
          if (!modTopic.tagsJson) {
            modTopic.tagsJson = []
          }
          if (!modTopic.tagsJson.includes(tag)) {
            modTopic.tagsJson.push(tag)
          }
          return modTopic
        })
        const resp = await Promise.all(taggedTopics.map(topic => this.$store.dispatch('forum/submitTopic', { topic })))
        if (resp) {
          this.$toast(`${taggedTopics.length} topics tagged.`, 'success')
        }
        this.loading = false
      }
    }
  }
}
</script>
